<template>
  <div>
    <b-card no-body class="mb-0" v-if="projectDetails">
      <div class="d-flex flex-column m-2">

        <div class="d-flex flex-row justify-content-between">

          <div class="d-flex flex-column">
            <div class="d-flex flex-row justify-between">
              <div class="profile-picture">

                <img src="/projects/project.svg" class="rounded-pill mb-3" width="110" height="110"
                  alt="/projects/project.svg">
              </div>
              <div class="d-flex flex-column justify-content-around h-100">
                <div>
                  <span class="project-title">
                    {{ projectDetails ? projectDetails.name : '' }}
                    <span class="project-number"> ( {{ projectDetails ? projectDetails.number : '' }} ) </span>
                  </span>
                </div>
                <div>
                  <span class="project-type"> {{ projectDetails ? projectDetails.type.text : '' }} </span>
                </div>
                <div>
                  <span> {{ projectDetails ? projectDetails.projectStatus.text : '' }} </span>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="d-flex flex-column">
            <div>
              <b-button variant="primary">
                <span class="text-nowrap">Download as PDF </span>
              </b-button>
            </div>
          </div> -->

        </div>

        <div class="d-flex flex-row mt-3 flex-wrap mb-3">

          <div class="d-flex flex-column justify-content-between h-100 p-1 mr-1 border-right">
            <div class="mb-1">
              <span> Client Name </span>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <div v-if="projectDetails.ownerImage" class="profile-icon-picture">
                <img :src="projectDetails.ownerImage" class="rounded-pill mb-3" width="25" height="25"
                  alt="/projects/project.svg">
              </div>
              <div class="d-flex flex-column justify-content-around">
                <div v-if="projectDetails">
                  <b-link class="font-weight-bold d-block text-nowrap"
                    :to="{ name: 'UsersEdit', params: { userId: projectDetails.ownerId } }">
                    {{ projectDetails.ownerName }}
                  </b-link>
                </div>
              </div>
            </div>
          </div>

          <div v-if="projectDetails" class="d-flex flex-column justify-content-between h-100 p-1 border-right">
            <div class="mb-1">
              <span>Client Country</span>
            </div>
            <div class="d-flex flex-column justify-content-around">
              <div>
                <span> {{ projectDetails.ownerCountry ? projectDetails.ownerCountry : 'Not Selected' }} </span>
              </div>
            </div>
          </div>

          <div v-if="projectDetails.professional"
            class="d-flex flex-column justify-content-between h-100 p-1 border-right">
            <div class="mb-1">
              <span> Designer Name </span>
            </div>
            <div v-if="projectDetails && projectDetails.professional" class="d-flex flex-row justify-content-between">
              <div v-if="projectDetails.professionalImage" class="profile-icon-picture">
                <img :src="projectDetails.professionalImage" class="rounded-pill mb-3" width="25" height="25"
                  alt="/projects/project.svg">
              </div>
              <div class="d-flex flex-column justify-content-around">
                <div>
                  <b-link class="font-weight-bold d-block text-nowrap"
                    :to="{ name: 'FreelancersShow', params: { professionalId: projectDetails.professional.value } }">
                    {{ projectDetails.professional.text }}
                  </b-link>
                </div>
              </div>
            </div>
          </div>

          <div v-if="projectDetails.professional"
            class="d-flex flex-column justify-content-between h-100 p-1 border-right">
            <div class="mb-1">
              <span> Designer Country </span>
            </div>
            <div v-if="projectDetails && projectDetails.professionalCountry" class="d-flex flex-row">
              <!-- <div class="profile-icon-picture">

              </div> -->
              <div class="d-flex flex-column justify-content-around">
                <div>
                  <span> {{ projectDetails.professionalCountry.code }} </span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="projectDetails.country" class="d-flex flex-column justify-content-between h-100 p-1 border-right">
            <div class="mb-1">
              <span> Project Country </span>
            </div>
            <div class="d-flex flex-row">
              <!-- <div class="profile-icon-picture">

              </div> -->
              <div class="d-flex flex-column justify-content-around">
                <div>
                  <span> {{ projectDetails ? projectDetails.country.code : '' }} </span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="projectDetails.bainkomPercentage"
            class="d-flex flex-column justify-content-between h-100 p-1 border-right">
            <div class="mb-1">
              <span> Bainkom % </span>
            </div>
            <div class="d-flex flex-row">
              <!-- <div class="profile-icon-picture">

              </div> -->
              <div class="d-flex flex-column justify-content-around">
                <div>
                  <span> {{ projectDetails ? projectDetails.bainkomPercentage * 100 + '%' : '' }} </span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="projectDetails.amountInUSD" class="d-flex flex-column justify-content-between h-100 p-1">
            <div class="mb-1">
              <span> Full Amount </span>
            </div>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column justify-content-around">
                <div>
                  <span> {{ projectDetails ? projectDetails.fullAmountInUSD + '$' : '' }} </span>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column justify-content-between w-80 h-100 p-1">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column justify-content-around">
                <div v-if="offersLink">
                  Offers Link
                </div>
                <div>
                  <b-button variant="link" @click="generateOffersLink" class="p-0 mt-1">
                    <span class="text-nowrap">Generate link to offers </span>
                  </b-button>
                  <div className="">
                    {{ offersLink }}

                    <b-button class="offer-copy-btn" variant="primary" @click="copyOffersLink">
                      <feather-icon icon="CopyIcon" width="15" height="15" />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="d-flex flex-column justify-content-between h-100 p-1 border-left">
            <div class="mb-1">
              <span style="color:#c6975d;font-weight: 500 !important;"class="h-100  border-right">Is Testing: {{ toggleState }}</span>
            </div>
            <label class="toggle">
              <input type="checkbox" v-model="toggleState" @change="toggle" />
              <span class="slider"></span>
            </label>
          </div>
        </div>


        <b-tabs>
          <b-tab lazy active>
            <template #title>
              <span>Details</span>
            </template>
            <project-details-section :projectDetails="projectDetails" :generalDetails="generalDetails" />
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span>Timeline</span>
            </template>
            <project-timeline-section :projectId="projectId" />
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span>Chat</span>
            </template>
            <project-chat :projectId="projectId" />
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span>Submissions</span>
            </template>
            <div v-if="!projectDetails.isMilestone"><project-submissions-section :projectId="projectId" /></div>
            <div v-else><project-submissions-milestone-section :projectId="projectId" /></div>

          </b-tab>
          <b-tab lazy v-if="projectDetails.isMilestone">
            <template #title>
              <span>Payment Details</span>
            </template>
            <project-payment-section :projectId="projectId" />
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span>History</span>
            </template>
            <project-history-section :projectId="projectId" />
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span>Comments</span>
            </template>
            <project-comments-section :projectId="projectId" />
          </b-tab>
        </b-tabs>

      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem, BInputGroup, fontAwesomeIcon,
  BDropdownForm, BFormSelect, BFormDatepicker, BFormGroup, BTabs, BTab

} from 'bootstrap-vue';
import vSelect from 'vue-select';
import ProjectDetailsSection from './ProjectDetailsSection.vue'
import ProjectSubmissionsSection from './ProjectSubmissionsSection.vue';
import ProjectSubmissionsMilestoneSection from './ProjectSubmissionsMilestoneSection.vue';
import ProjectChat from './ProjectChat.vue'
import ProjectCommentsSection from './ProjectCommentsSection.vue'
import ProjectHistorySection from './ProjectHistorySection.vue'
import ProjectTimelineSection from './ProjectTimelineSection.vue'
import ProjectPaymentSection from './ProjectPaymentSection.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BBadge,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    fontAwesomeIcon,
    BDropdownForm,
    BFormSelect,
    BFormDatepicker,
    BFormGroup,
    BTabs,
    BTab,
    ProjectDetailsSection,
    ProjectChat,
    ProjectSubmissionsSection,
    ProjectSubmissionsMilestoneSection,
    ProjectCommentsSection,
    ProjectHistorySection,
    ProjectTimelineSection,
    ProjectPaymentSection
  },
  props: ['projectId'],
  data() {
    return {
      projectDetails: null,
      generalDetails: null,
      offersLink: null,
      toggleState: false,
    }
  },
  created() {
    this.fetchProjectDetails()
  },
  computed: {

    // Add a class based on the state (optional styling)
    toggleClass() {
      return this.toggleState ? 'on' : 'off';
    }
  },
  watch: {},
  methods: {

    toggle() {

      this.$http.post('Projects', {
        id: this.projectId,
        isTest: this.toggleState
      }).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'your project has been updated.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },


    async fetchProjectDetails() {
      const response = await this.$http.get(`Projects/Details/${this.projectId}`);

      this.projectDetails = response.data
      this.toggleState = this.projectDetails.isTest
      if (response.data.offersAccessTokenLink) {
        this.offersLink = response.data.offersAccessTokenLink
      }

      const generalDetails = JSON.parse(response.data.details)
      // this.generalDetails = generalDetails

      const res = Object.keys(generalDetails)
        .sort((a, b) => {

          let valueA = null
          let valueB = null

          if (generalDetails[a] !== null && generalDetails[b] != null) {

            if (typeof generalDetails[a] === 'object') {
              valueA = generalDetails[a].text
            } else {
              valueA = generalDetails[a]
            }

            if (typeof generalDetails[b] === 'object') {
              valueB = generalDetails[b].text
            } else {
              valueB = generalDetails[b]
            }

          } else {
            valueA = ''
            valueB = ''
          }

          return valueA.toString().length - valueB.toString().length

        })
        .reduce((acc, key) => ((acc[key] = generalDetails[key]), acc), {})

      this.generalDetails = res

    },
    async generateOffersLink() {
      const response = await this.$http.get(`Projects/${this.projectId}/Offers/Link`);
      this.offersLink = response.data;
    },
    async copyOffersLink() {
      navigator.clipboard.writeText(this.offersLink);
    }
  },
}
</script>

<style lang="scss" scoped>
.profile-picture {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #191832;
  margin-right: 25px;
}

.profile-icon-picture {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #191832;
  margin-right: 10px;
}

.border-right {
  border-right: 1px solid black;
}

.project-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 39.01px;
}

.project-number {
  font-weight: 500;
  font-size: 22px;
  line-height: 26.82px;
}

.project-type {
  font-weight: 500;
  font-size: 22px;
  line-height: 26.82px;
}

.on {
  background-color: green;
  color: white;
}

.off {
  background-color: red;
  color: white;
}

.offer-copy-btn {
  padding: 5px;
}

/* Hide the Checkbox */
.toggle input {
  display: none;
  /* Completely hides the checkbox */
}

/* Toggle Switch Styling */
.toggle {
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
  /* Spacing between text and toggle */
}

.toggle-text {
  color: #333;
}

.slider {
  position: relative;
  width: 45px;
  height: 20px;
  background-color: #ddd;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slider::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

/* Checked State */
input:checked+.slider {
  background-color: #c6975d;
  /* Green for "On" state */
}

input:checked+.slider::before {
  transform: translate(25px, -50%);
}
</style>